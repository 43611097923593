import React from "react"
import { useIsLessThanLg, Text, CenterAligned } from "@opensea/ui-kit"
import {
  CountdownCellProps,
  useCountdownIntervalDisplay,
} from "@/components/common/CountdownTimer/components/CountdownTimerCell/CountdownTimerCell.react"

export const RedeemableCountdownTimerCell = ({
  interval,
  value,
}: CountdownCellProps) => {
  const isLessThanLg = useIsLessThanLg()
  const intervalDisplay = useCountdownIntervalDisplay({ interval, value })

  return (
    <CenterAligned className="h-[52px] w-[52px] rounded-lg bg-component-gray-1 backdrop-blur-md lg:h-[72px] lg:w-[72px]">
      {isLessThanLg ? (
        <Text.Body>{value}</Text.Body>
      ) : (
        <Text.Heading size="medium">{value}</Text.Heading>
      )}

      <Text.Body
        className="text-secondary"
        size={isLessThanLg ? "tiny" : "small"}
      >
        {intervalDisplay}
      </Text.Body>
    </CenterAligned>
  )
}
