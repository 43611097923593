import { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsAnalyticsTabEnabled_collection$key } from "@/lib/graphql/__generated__/useIsAnalyticsTabEnabled_collection.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { graphql, useFragment } from "react-relay"

const ANALYTICS_TAB_DEFAULT_MAX_ITEM_QUANTITY_SUPPORTED = 200_000

export const CHAINS_WITH_ETH_NATIVE_PAYMENT_ASSET: ChainIdentifier[] = [
  // Mainnet
  "ARBITRUM",
  "BASE",
  "BLAST",
  "SEI",
  "ETHEREUM",
  "OPTIMISM",
  "MATIC",
  "ARBITRUM_NOVA",
  "ZORA",
  "APE_CHAIN",
  "B3",
  "SONEIUM",
  "SHAPE",
  "UNICHAIN",
  // Testnet
  "ARBITRUM_GOERLI",
  "ARBITRUM_SEPOLIA",
  "BASE_GOERLI",
  "BASE_SEPOLIA",
  "BLAST_SEPOLIA",
  "GUNZILLA_TESTNET",
  "SEI_DEVNET",
  "SEI_TESTNET",
  "GOERLI",
  "OPTIMISM_GOERLI",
  "OPTIMISM_SEPOLIA",
  "MUMBAI",
  "AMOY",
  "SEPOLIA",
  "ZORA_TESTNET",
  "ZORA_SEPOLIA",
  "B3_SEPOLIA",
  "FLOW_TESTNET",
  "APE_CURTIS",
  "SONEIUM_MINATO",
  "MONAD_TESTNET",
]

export const useIsAnalyticsTabEnabled = (
  dataKey: useIsAnalyticsTabEnabled_collection$key | null,
) => {
  const data = useFragment(
    graphql`
      fragment useIsAnalyticsTabEnabled_collection on CollectionType {
        defaultChain {
          identifier
        }
        statsV2 {
          totalQuantity
        }
      }
    `,
    dataKey,
  )

  // disabled until we have data
  if (!data) {
    return false
  }

  const isWithinItemLimit = bn(data.statsV2.totalQuantity).isLessThan(
    ANALYTICS_TAB_DEFAULT_MAX_ITEM_QUANTITY_SUPPORTED,
  )
  if (!isWithinItemLimit) {
    return false
  }

  return CHAINS_WITH_ETH_NATIVE_PAYMENT_ASSET.includes(
    data.defaultChain.identifier,
  )
}
