/**
 * @generated SignedSource<<f2a09c819d9d1a15934fdf09792b51b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StageStartCountdown_stage$data = {
  readonly startTime: string;
  readonly " $fragmentType": "StageStartCountdown_stage";
};
export type StageStartCountdown_stage$key = {
  readonly " $data"?: StageStartCountdown_stage$data;
  readonly " $fragmentSpreads": FragmentRefs<"StageStartCountdown_stage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StageStartCountdown_stage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    }
  ],
  "type": "DropStageV2Type",
  "abstractKey": "__isDropStageV2Type"
};

(node as any).hash = "12e4fe3bc61b3e3f30f524a9aff552bc";

export default node;
