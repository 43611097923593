import React, { useMemo } from "react"
import { getAppInitialProps } from "@/components/app/initialProps"
import {
  AssetCardVariant,
  getCardVariantFromCookie,
} from "@/components/search/assets/AssetSearchView"
import { useSearch } from "@/hooks/useSearch"
import type { CollectionPageQuery } from "@/lib/graphql/__generated__/CollectionPageQuery.graphql"
import { graphql, GraphQLInitialProps } from "@/lib/graphql/graphql"
import type { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { CollectionPageContainer } from "./CollectionPageContainer.react"
import {
  CollectionAssetSearch,
  CollectionAssetSearchState,
} from "./components/CollectionAssetSearch/CollectionAssetSearch.react"

type Props = {
  assetCardVariant?: AssetCardVariant
  searchState: CollectionAssetSearchState
}

export const CollectionPage: GraphQLNextPage<CollectionPageQuery, Props> = ({
  data,
  assetCardVariant,
  searchState: initialSearchState,
}) => {
  const defaultSearchState = useMemo<CollectionAssetSearchState>(
    () => ({
      collection: initialSearchState.collection,
      collections: initialSearchState.collections,
      numericTraits: undefined,
      paymentAssets: undefined,
      priceFilter: undefined,
      query: undefined,
      rarityFilter: undefined,
      resultModel: "ASSETS",
      sortAscending: true,
      sortBy: "UNIT_PRICE",
      stringTraits: undefined,
      owner: undefined,
      toggles: undefined,
      filterOutListingsWithoutRequestedCreatorFees: undefined,
    }),
    [initialSearchState.collection, initialSearchState.collections],
  )

  const { searchState, clear, update } = useSearch({
    defaultState: defaultSearchState,
    initialState: initialSearchState,
  })

  return (
    <CollectionPageContainer
      data={data}
      initialSidebarOpen
      stringTraits={searchState.stringTraits}
      tab="items"
    >
      <CollectionAssetSearch
        assetCardVariant={assetCardVariant}
        clear={clear}
        // Resets filter/searchState when we switch collections
        key={searchState.collection}
        searchState={searchState}
        update={update}
      />
    </CollectionPageContainer>
  )
}

const query = graphql`
  query CollectionPageQuery($collection: CollectionSlug!) {
    collection(collection: $collection) {
      __typename
    }
    ...CollectionPageContainer_data @arguments(collection: $collection)
  }
`

CollectionPage.getInitialProps = QP.nextParser(
  {
    collectionSlug: QP.string,
    search: QP.Optional(QP.Search),
  },
  async (
    { collectionSlug, search },
    ctx,
  ): Promise<GraphQLInitialProps<CollectionPageQuery, Props>> => {
    const searchState: CollectionAssetSearchState = {
      collection: collectionSlug,
      collections: [collectionSlug],
      numericTraits: search?.numericTraits,
      paymentAssets: search?.paymentAssets,
      priceFilter: search?.priceFilter,
      query: search?.query,
      rarityFilter: search?.rarityFilter,
      resultModel: search?.resultModel ?? "ASSETS",
      sortAscending: search?.sortAscending ?? true,
      sortBy: search?.sortBy ?? "UNIT_PRICE",
      stringTraits: search?.stringTraits,
      owner: search?.owner,
      toggles: search?.toggles,
      filterOutListingsWithoutRequestedCreatorFees:
        search?.filterOutListingsWithoutRequestedCreatorFees,
    }

    const assetCardVariant = getCardVariantFromCookie("global", ctx)
    const variables = { collection: collectionSlug }
    const appInitialProps = await getAppInitialProps<CollectionPageQuery>(ctx, {
      query,
      variables,
      cachePolicy: {
        maxAge: 10,
        revalidate: 20,
      },
      isPageNotFound: data => {
        return data !== null && data.collection === null
      },
    })

    return {
      ...appInitialProps,
      assetCardVariant,
      searchState,
      variables,
    }
  },
)
