import React, { useState } from "react"
import { Flex, FlexProps } from "@opensea/ui-kit"
import {
  isAfter,
  intervalToDuration,
  Duration,
  differenceInCalendarDays,
} from "date-fns"
import { useInterval } from "react-use"
import styled, { css } from "styled-components"
import { blurClearBackground } from "@/features/primary-drops/styleUtils"
import { RedeemableCountdownTimerCell as LargeCountdownTimerCell } from "@/features/redeemables/components/RedeemPage/components/RedeemPageHeader/RedeemableCountdownTimerCell.react"
import { CountdownTimerCell as SmallCountdownTimerCell } from "./components/CountdownTimerCell"

export type CountdownTimerProps = {
  end: Date
  translucent?: boolean
  size?: "small" | "large"
} & FlexProps

export const CountdownTimer = ({
  end,
  translucent,
  size = "small",
  ...rest
}: CountdownTimerProps) => {
  const now = Date.now()
  const getDurationToEnd = () => {
    return isAfter(end, now)
      ? intervalToDuration({
          start: now,
          end,
        })
      : { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 }
  }

  const [duration, setDuration] = useState<Duration>(getDurationToEnd())

  useInterval(() => setDuration(getDurationToEnd()), 1000)

  const {
    years = 0,
    months = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = duration

  // If the drop is more than a month away, calculate the actual number of days left
  const actualDays =
    years > 0 || months > 0 ? differenceInCalendarDays(end, now) : days

  const CountdownTimerCell =
    size === "large" ? LargeCountdownTimerCell : SmallCountdownTimerCell

  return (
    <CountdownContainer $translucent={translucent} {...rest}>
      <CountdownTimerCell interval="day" value={actualDays} />
      <CountdownTimerCell interval="hour" value={hours} />
      <CountdownTimerCell interval="min" value={minutes} />
      <CountdownTimerCell interval="sec" value={seconds} />
    </CountdownContainer>
  )
}

const CountdownContainer = styled(Flex)<{
  $translucent?: boolean
}>`
  & > div {
    ${({ $translucent }) =>
      $translucent &&
      css`
        ${blurClearBackground}

        span {
          color: white;
        }
      `}
  }
`
