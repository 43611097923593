import { useCallback, useEffect, useState } from "react"
import { isEmpty, isEqual } from "lodash"
import { useUpdateEffect } from "react-use"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useRouter } from "@/hooks/useRouter"
import {
  PriceFilterType,
  SearchResultModel,
  SearchSortBy,
  SearchToggle,
  TraitInputType,
  TraitRangeType,
} from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import {
  OfferTypeToggle,
  OrderStatusToggle,
} from "@/lib/graphql/__generated__/AccountOffersOrderSearchListPaginationQuery.graphql"
import { OrderSortOption } from "@/lib/graphql/__generated__/AccountOffersOrderSearchListQuery.graphql"
import { RarityFilterType } from "@/lib/graphql/__generated__/CollectionAssetSearchListQuery.graphql"
import { IdentityInputType } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { EventType } from "@/lib/graphql/__generated__/ItemType.graphql"
import { SafelistRequestStatus } from "@/lib/graphql/__generated__/useCollectionFormEditMutation.graphql"
import { keys } from "@/lib/helpers/object"
import { stringifyQueryParams } from "@/lib/helpers/urls"

export type Search = {
  categories?: readonly string[]
  chains?: readonly ChainIdentifier[]
  collection?: string
  collections?: readonly string[]
  eventTypes?: readonly EventType[]
  identity?: IdentityInputType
  numericTraits?: readonly TraitRangeType[]
  paymentAssets?: readonly string[]
  priceFilter?: PriceFilterType
  rarityFilter?: RarityFilterType
  query?: string
  resultModel?: SearchResultModel
  sortAscending?: boolean
  sortBy?: SearchSortBy
  orderSortBy?: OrderSortOption
  orderStatusToggles?: readonly OrderStatusToggle[]
  offerTypeToggles?: readonly OfferTypeToggle[]
  stringTraits?: readonly TraitInputType[]
  toggles?: readonly SearchToggle[]
  safelistRequestStatuses?: readonly SafelistRequestStatus[]
  isAutoHidden?: boolean
  creator?: IdentityInputType
  owner?: IdentityInputType
  filterOutListingsWithoutRequestedCreatorFees?: boolean
}

export type SearchType =
  | "OfferSearch"
  | "ActivitySearch"
  | "ActivityCollectionSearch"
  | "ActivityAccountSearch"
  | "AssetSearch"
  | "AssetCollectionSearch"
  | "ListingSearch"

const NON_POPULATED_SEARCH_STATE_IN_URL = [
  "safelistRequestStatuses",
  "identity",
] as const

type Props<T extends Search> = {
  // This is the default state that will be used when the user clears the search
  defaultState: T
  // This state should always be present, and cannot be changed by the user. i.e the collection page would have collection as a fixed state
  fixedState?: Partial<T>
  // This state is the initial state that will be used when the user first visits the page.
  initialState: Partial<T>
  // Used to generate the correct path when updating the route
  path?: string
}

export const useSearch = <T extends Search>({
  defaultState,
  fixedState = {},
  initialState,
  path,
}: Props<T>) => {
  const [searchState, setSearchState] = useState<T>({
    ...defaultState,
    ...initialState,
    ...fixedState,
  })
  const router = useRouter()

  const toSearchQueryParams = useCallback(
    (state: T) => {
      const search = { ...state }
      const stateToCheck = { ...defaultState, ...fixedState }

      keys(search)
        .filter(
          k =>
            !((k as string) in stateToCheck) || stateToCheck[k] === search[k],
        )
        .forEach(k => {
          delete search[k]
        })

      delete search.collection

      // These are hidden states that don't need to be populated in the url
      for (const key of NON_POPULATED_SEARCH_STATE_IN_URL) {
        delete search[key]
      }

      return search
    },
    [defaultState, fixedState],
  )

  const getSanitizedState = useCallback(
    (updatedState: Partial<T> | undefined) => {
      const checkSafelistedOnly = !(
        searchState.query ||
        searchState.sortBy ||
        searchState.orderSortBy ||
        searchState.collection ||
        searchState.collections
      )

      const newSearchState: T = {
        ...defaultState,
        ...updatedState,
        ...fixedState,
        ...(checkSafelistedOnly
          ? { safelistRequestStatuses: ["APPROVED", "VERIFIED"] }
          : undefined),
      }

      return newSearchState
    },
    [defaultState, fixedState, searchState],
  )

  // This is mostly to handle the cases where users are pressing back/forward on their browser and thus changing the route
  const handleRouteChange = async () => {
    const searchQueryParam = Object.fromEntries(
      Object.entries(router.queryParams.search ?? {}).map(([k, v]) => [
        k,
        // TODO: Consider changing router.queryParams to do this automatically
        // Map params that are "true" or "false" to primitive boolean values
        v === "true" ? true : v === "false" ? false : v,
      ]),
    ) as Partial<T>

    const collectionSlug = router.query.collectionSlug
      ? Array.isArray(router.query.collectionSlug)
        ? router.query.collectionSlug[0]
        : router.query.collectionSlug
      : searchQueryParam.collections?.[0]

    const collections =
      searchQueryParam.collections ??
      (collectionSlug ? [collectionSlug] : undefined)

    const search = {
      collection: collectionSlug,
      collections,
      ...searchQueryParam,
    } as Partial<T>

    const newSearchState = getSanitizedState(
      collectionSlug || !isEmpty(searchQueryParam) ? search : undefined,
    )

    if (
      // Safelist request statuses are more of a "hidden" state that we don't need to check equality for.
      !isEqual(
        { ...searchState, safelistRequestStatuses: undefined },
        { ...newSearchState, safelistRequestStatuses: undefined },
      )
    ) {
      setSearchState(newSearchState)
    }
  }

  // Opt to handle route changes with useEffect as opposed to using router.events.on('routeChangeComplete', ...)
  // as the interface for the router change handlers is not very useful or easy to work with as well
  useUpdateEffect(() => {
    handleRouteChange()
  }, [router.query])

  const updateRoute = useCallback(
    (state: T) => {
      // We don't want to do any route changes if the state hasn't actually changed at all
      if (isEqual(state, searchState)) {
        return
      }

      // TODO: change router.queryParams to be more suitable for passing into router.push
      const queryParams = toSearchQueryParams(state)
      const populateQuery = Object.keys(queryParams).length > 0

      const query = populateQuery
        ? stringifyQueryParams(
            {
              search: queryParams,
            },
            { addQueryPrefix: false },
          )
        : undefined

      if (!path) {
        router.push({ pathname: router.asPathWithoutQuery, query }, undefined, {
          scroll: false,
          shallow: true,
        })

        return
      }

      router.push(
        {
          pathname: path,
          query,
        },
        undefined,
        { scroll: false, shallow: true },
      )
    },
    [path, router, toSearchQueryParams, searchState],
  )

  const update = useCallback(
    async (updatedState?: Partial<T>) => {
      const newSearchState = getSanitizedState({
        ...searchState,
        ...updatedState,
      })
      setSearchState(newSearchState)

      updateRoute(newSearchState)
    },
    [getSanitizedState, searchState, updateRoute],
  )

  const clear = useCallback(() => {
    update({ ...defaultState, ...fixedState })
  }, [defaultState, fixedState, update])

  // Clear string traits if multiple collections are selected
  const hasTraitFiltersApplied =
    searchState.stringTraits && searchState.stringTraits.length > 0
  const hasMultipleCollectionFiltersApplied =
    searchState.collections && searchState.collections.length > 1
  useEffect(() => {
    if (hasTraitFiltersApplied && hasMultipleCollectionFiltersApplied) {
      update({ stringTraits: undefined } as Partial<T>)
    }
  }, [hasTraitFiltersApplied, hasMultipleCollectionFiltersApplied, update])

  return { searchState, update, clear }
}
