import { rgba } from "polished"
import styled, { css } from "styled-components"
import { variant } from "styled-system"
import { Block } from "@/design-system/Block"
import { Values } from "@/lib/helpers/type"
import { media } from "@/styles/styleUtils"

export const LIVE_PULSE_VARIANT = {
  LIVE: "live",
  PAUSED: "paused",
  INACTIVE: "inactive",
} as const

type LivePulseVariant = Values<typeof LIVE_PULSE_VARIANT>

export const LivePulse = styled(Block)<{
  variant: LivePulseVariant
}>`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 12px;

  ${props =>
    variant({
      variants: {
        live: { background: props.theme.colors.seaGrass },
        paused: { background: props.theme.colors.starFish },
        inactive: { backgroundColor: props.theme.colors.white },
      },
    })}

  ${props =>
    props.variant === LIVE_PULSE_VARIANT.LIVE &&
    css`
      animation: livePulse 3s ease infinite;
      @keyframes livePulse {
        0% {
          box-shadow: 0 0 0 0 ${rgba(props.theme.colors.seaGrass, 1)};
        }
        70% {
          box-shadow: 0 0 0 7px ${rgba(props.theme.colors.seaGrass, 0)};
        }
        100% {
          box-shadow: 0 0 0 0 ${rgba(props.theme.colors.seaGrass, 0)};
        }
      }
    `}

  ${media({
    lg: css`
      margin-right: 12px;
      width: 12px;
      height: 12px;
    `,
  })}
`
