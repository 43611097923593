import React from "react"
import { parseJSON } from "date-fns"
import { useFragment } from "react-relay"
import styled from "styled-components"
import {
  CountdownTimer,
  CountdownTimerProps,
} from "@/components/common/CountdownTimer"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { StageStartCountdown_stage$key } from "@/lib/graphql/__generated__/StageStartCountdown_stage.graphql"
import { graphql } from "@/lib/graphql/graphql"

type StageStartCountdownProps = Omit<CountdownTimerProps, "end"> & {
  dataKey: StageStartCountdown_stage$key | null
}

export const StageStartCountdown = ({
  dataKey,
  ...countdownTimerProps
}: StageStartCountdownProps) => {
  const data = useFragment(
    graphql`
      fragment StageStartCountdown_stage on DropStageV2Type {
        startTime
      }
    `,
    dataKey,
  )

  if (!data) {
    return null
  }

  return (
    // don't SSR this because the countdown text can change between the time it
    // takes to render on server and client, causing a hydration error
    <SsrSuspense fallback={null}>
      <StageStartCountdownTimer
        end={parseJSON(data.startTime)}
        {...countdownTimerProps}
      />
    </SsrSuspense>
  )
}

const StageStartCountdownTimer = styled(CountdownTimer)`
  grid-gap: 8px;
`
