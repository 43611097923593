import { rgba } from "polished"
import { css } from "styled-components"
import { classNames, media, themeVariant } from "@/styles/styleUtils"

export const fullBleedCss = css`
  aspect-ratio: 12/5;
  padding: 0px;
  width: 100vw;
`

export const metadataContainerStyles = css`
  min-width: min(600px, 100%);
  background-color: ${({ theme }) =>
    theme.colors.components.elevation.level1.regular.background};
  box-shadow: ${({ theme }) =>
    theme.colors.components.elevation.level1.regular.shadow};
  ${media({
    xs: css`
      padding: 16px 24px 16px 24px;
      border-radius: ${props => props.theme.borderRadius.default};
    `,
    lg: css`
      padding: 24px 32px 24px 32px;
      border-radius: ${props => props.theme.borderRadius.xlarge};
    `,
  })}
`

export const IMAGE_SIZES = {
  xs: "68px",
  sm: "92px",
  lg: "140px",
}

export const metadataImageStyles = css`
  overflow: hidden;
  position: relative;
  ${media({
    xs: css`
      --size: ${IMAGE_SIZES.xs};
      margin-right: 16px;
    `,
    sm: css`
      --size: ${IMAGE_SIZES.sm};
    `,
    lg: css`
      --size: ${IMAGE_SIZES.lg};
      margin-right: 24px;
    `,
  })}
  min-height: var(--size);
  min-width: var(--size);
  max-height: var(--size);
  max-width: var(--size);
`

export const textContainerStyles = css`
  width: 100%;
`

export const linkParentCss = css`
  a {
    color: white;

    &:hover {
      color: white;
      opacity: 0.7;
    }
  }
`

export const nonInteractiveOverlay = css`
  pointer-events: none;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
`

export const stickyBarHeightVar = css`
  ${media({
    xs: css`
      --sticky-bar-height: 68px;
    `,
    lg: css`
      --sticky-bar-height: 88px;
    `,
  })}
`

export const blurClearBackground = css`
  background-color: ${rgba("#FFFFFF", 0.24)};
  backdrop-filter: blur(20px);
`

export const blurClearButton = css`
  ${blurClearBackground}
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.borderRadius.default};
  color: white;
  i {
    color: white;
  }
  padding: 0px 16px;
  --button-height: 44px;
  border-radius: ${props => props.theme.borderRadius.default};
  ${media({
    lg: css`
      border-radius: ${props => props.theme.borderRadius.large};
      padding: 0px 24px;
      --button-height: 60px;
    `,
  })}
`

export const topPinnedCtaStyles = css`
  padding: 0;
  top: 12px;
  right: 12px;

  ${media({
    sm: css`
      top: 16px;
      right: 16px;
    `,
    lg: css`
      top: 24px;
      right: 24px;
    `,
  })}

  button {
    padding: 0;
  }
`

export const shadowDropStyle = css`
  transition: 0.2s box-shadow ease-in-out;
  box-shadow: ${({ theme }) =>
    theme.colors.components.elevation.level1.regular.shadow};
  background-color: ${({ theme }) =>
    theme.colors.components.elevation.level1.regular.background};

  ${themeVariant({
    variants: {
      dark: {
        border: "none",
      },
    },
  })}
`

export const shadowDropButtonStyle = css`
  transition:
    400ms box-shadow ease-out,
    400ms transform ease-out;
  &:hover {
    box-shadow: ${({ theme }) =>
      theme.colors.components.elevation.level2.shadow};
    background-color: ${({ theme }) =>
      theme.colors.components.elevation.level2.background};
    transform: translate(0, -4px);
    cursor: pointer;
  }
  &:active {
    transform: translate(0, 0);
    box-shadow: ${({ theme }) =>
      theme.colors.components.elevation.level1.regular.shadow};
    background-color: ${({ theme }) =>
      theme.colors.components.elevation.level1.regular.background};
  }
`

export const hideSideNavStyle = css`
  ${media({
    lg: css`
      left: 32px;
      right: 32px;
    `,
    xxl: css`
      left: 64px;
      right: 64px;
    `,
  })}
`

export const borderColorDropStyle = css`
  ${props =>
    themeVariant({
      variants: {
        light: {
          borderColor: rgba(props.theme.colors.smoke, 0.12),
        },
        dark: {
          borderColor: rgba(props.theme.colors.white, 0.12),
        },
      },
    })}
`

export const contentContainerStyles = classNames("mx-auto max-w-[1600px]")

export const textContainerClasses = classNames(
  "relative justify-center xs:min-h-[68px] xs:gap-1 md:min-h-[92px] md:gap-2 lg:min-h-[140px]",
)

export const metadataContainerClasses = classNames(
  "min-w-[min(600px,100%)] bg-elevation-1 shadow-elevation-1 xs:rounded-[10px] xs:px-6 xs:py-4 lg:rounded-2xl lg:px-8 lg:py-6",
)
