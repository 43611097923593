import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { useTranslate } from "@/hooks/useTranslate"
import { media } from "@/styles/styleUtils"

export type CountdownCellProps = {
  interval: "year" | "month" | "day" | "hour" | "min" | "sec"
  value: number
}

export const CountdownTimerCell = ({ interval, value }: CountdownCellProps) => {
  const intervalDisplay = useCountdownIntervalDisplay({ interval, value })
  return (
    <CellContainer>
      <HeadingText>{value}</HeadingText>
      <SubheadingText>{intervalDisplay}</SubheadingText>
    </CellContainer>
  )
}

export const CountdownCellContainerHeight = css`
  min-height: 44px;
  ${media({
    lg: css`
      min-height: 60px;
    `,
  })}
`

export const useCountdownIntervalDisplay = ({
  interval,
  value,
}: CountdownCellProps) => {
  const t = useTranslate("components")

  const translatedIntervals = {
    year: t(
      "countdownTimer.year",
      { 0: "yrs", one: "yr", other: "yrs" },
      { count: value },
      { forceString: true },
    ),
    month: t(
      "countdownTimer.month",
      {
        0: "mos",
        one: "mo",
        other: "mos",
      },
      { count: value },
      { forceString: true },
    ),
    day: t(
      "countdownTimer.day",
      { 0: "days", one: "day", other: "days" },
      { count: value },
      { forceString: true },
    ),
    hour: t(
      "countdownTimer.hr",
      { 0: "hrs", one: "hr", other: "hrs" },
      { count: value },
      { forceString: true },
    ),
    min: t(
      "countdownTimer.min",
      { 0: "mins", one: "min", other: "mins" },
      { count: value },
      { forceString: true },
    ),
    sec: t(
      "countdownTimer.sec",
      { 0: "secs", one: "sec", other: "secs" },
      { count: value },
      { forceString: true },
    ),
  }

  return translatedIntervals[interval]
}

const CellContainer = styled(CenterAligned)`
  ${CountdownCellContainerHeight}
  border-radius: 8px;
  width: 44px;
  background-color: ${props => props.theme.colors.components.background.gray1};

  ${media({
    lg: css`
      width: 60px;
      border-radius: ${props => props.theme.borderRadius.large};
    `,
  })}
`

const HeadingText = styled(Text.Body).attrs({
  size: "tiny",
  weight: "semibold",
})`
  letter-spacing: 2px;
  text-indent: 2px;

  ${media({
    lg: css`
      font-size: 16px;
      line-height: 24px;
    `,
  })}
`

const SubheadingText = styled(Text.Body).attrs({
  size: "tiny",
  weight: "semibold",
})`
  font-size: 10px;
  line-height: 12px;
  text-indent: 0.1em;

  ${media({
    lg: css`
      font-size: 12px;
      line-height: 20px;
    `,
  })}
`
